var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('Parent'), _c('validation-observer', {
    ref: "observer"
  }, [_c('v-form', {
    ref: "post_form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("page_notifications_edit_title")) + " ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', [_vm.showDescription ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-right mb-2"
  }, [_c('validation-provider', {
    attrs: {
      "name": "post_date",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-dialog', {
          ref: "post_date_dialog",
          attrs: {
            "return-value": _vm.post_date,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              _vm.post_date = $event;
            },
            "update:return-value": function updateReturnValue($event) {
              _vm.post_date = $event;
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "hint-text",
                attrs: {
                  "text": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("posted_date")) + ": " + _vm._s(_vm.post_date) + " "), _c('v-icon', {
                attrs: {
                  "size": "16",
                  "right": ""
                }
              }, [_vm._v("$edit")])], 1), _c('v-text-field', _vm._g(_vm._b({
                staticClass: "no-border",
                attrs: {
                  "dense": "",
                  "hidden": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.post_date,
                  callback: function callback($$v) {
                    _vm.post_date = $$v;
                  },
                  expression: "post_date"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, true),
          model: {
            value: _vm.post_date_model,
            callback: function callback($$v) {
              _vm.post_date_model = $$v;
            },
            expression: "post_date_model"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "scrollable": ""
          },
          model: {
            value: _vm.post_date,
            callback: function callback($$v) {
              _vm.post_date = $$v;
            },
            expression: "post_date"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.post_date_model = false;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.post_date_dialog.save(_vm.post_date);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("ok")) + " ")])], 1)], 1)];
      }
    }], null, false, 1305281398)
  })], 1), _c('validation-provider', {
    attrs: {
      "name": "title",
      "rules": _vm.rules.title
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          staticClass: "post-title",
          attrs: {
            "solo": "",
            "hide-details": "",
            "placeholder": _vm.$t('post_title'),
            "dense": "",
            "error-messages": errors,
            "maxlength": "100"
          },
          on: {
            "input": function input($event) {
              _vm.enteredTextTitle = _vm.title.length;
            }
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), errors[0] ? _c('div', {
          staticClass: "error--text m-0"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()];
      }
    }], null, false, 356807241)
  }), [_c('div', {
    staticClass: "hint-text text-md-right"
  }, [_vm._v(" " + _vm._s(_vm.$t("word_count", {
    count: _vm.enteredTextTitle
  })) + " ")])]], 2) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    ref: "previewTitle"
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_vm.showDescription ? _c('PostEditor', {
          attrs: {
            "data": _vm.description,
            "error-messages": errors
          },
          on: {
            "update:data": function updateData($event) {
              _vm.description = $event;
            }
          }
        }) : _vm._e(), _c('div', {
          ref: "preview"
        }), errors[0] ? _c('div', {
          staticClass: "error--text m-0"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "order": "last",
      "order-md": "first"
    }
  }, [_c('v-card', {
    staticClass: "text-center"
  }, [_c('v-card-text', {
    staticClass: "px-0 py-0"
  }, [_c('v-btn', {
    staticClass: "custom-button",
    attrs: {
      "tile": "",
      "block": "",
      "text": "",
      "depressed": "",
      "min-height": "50"
    },
    on: {
      "click": function click($event) {
        return _vm.submit('draft');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save_draft")) + " ")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "px-0 py-0"
  }, [_vm.showDescription ? _c('v-btn', {
    staticClass: "custom-button",
    attrs: {
      "tile": "",
      "block": "",
      "text": "",
      "depressed": "",
      "min-height": "50"
    },
    on: {
      "click": _vm.showPreview
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("preview")) + " ")]) : _c('v-btn', {
    staticClass: "custom-button",
    attrs: {
      "tile": "",
      "block": "",
      "text": "",
      "depressed": "",
      "min-height": "50"
    },
    on: {
      "click": _vm.disablePreview
    }
  }, [_vm._v(" 編集モード ")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-btn', {
    staticClass: "custom-button delete",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteItemDlg = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("delete_post")) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-btn', {
    staticClass: "custom-button",
    attrs: {
      "type": "submit",
      "min-width": "100",
      "color": "primary",
      "depressed": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("publish")) + " ")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "px-0 py-0"
  }, [_c('v-container', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center label-type",
    attrs: {
      "cols": "3",
      "md": "12",
      "lg": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("type")) + " ")]), _c('v-divider', {
    attrs: {
      "vertical": this.$vuetify.breakpoint.name !== 'md'
    }
  }), _c('v-col', {
    staticClass: "px-2 py-0",
    attrs: {
      "cols": "9",
      "md": "12",
      "lg": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "selectedType",
      "rules": _vm.rules.selectedType
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          staticClass: "rounded-0 font-12px notify-type-select",
          attrs: {
            "solo": "",
            "hide-details": "",
            "flat": "",
            "color": "primary",
            "item-text": "name",
            "item-value": "id",
            "label": "Select Type",
            "items": _vm.notificationTypes,
            "error-messages": errors
          },
          model: {
            value: _vm.selectedType,
            callback: function callback($$v) {
              _vm.selectedType = $$v;
            },
            expression: "selectedType"
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "category-card"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("category")) + " ")]), _c('v-divider'), _c('v-card-text', [_c('validation-provider', {
    attrs: {
      "name": "selectedCategory",
      "rules": _vm.rules.selectedCategory
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-radio-group', {
          staticClass: "mt-0",
          attrs: {
            "error-messages": errors
          },
          model: {
            value: _vm.selectedCategory,
            callback: function callback($$v) {
              _vm.selectedCategory = $$v;
            },
            expression: "selectedCategory"
          }
        }, _vm._l(_vm.categories, function (category) {
          return _c('v-radio', {
            key: category.id,
            attrs: {
              "label": category.name,
              "value": category.id,
              "ripple": false
            }
          });
        }), 1)];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "category-card"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$tc("tag", 2)) + " ")]), _c('v-divider'), _c('v-card-text', [_c('validation-provider', {
    attrs: {
      "name": "selectedTags",
      "rules": _vm.rules.selectedTags
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-autocomplete', {
          staticClass: "multiple-select-tag",
          attrs: {
            "error-messages": errors,
            "items": _vm.tags,
            "item-text": "name",
            "item-value": "name",
            "menu-props": {
              maxHeight: '400'
            },
            "label": "タグを選択してください。",
            "multiple": "",
            "dense": "",
            "chips": "",
            "deletable-chips": "",
            "small-chips": ""
          },
          model: {
            value: _vm.selectedTags,
            callback: function callback($$v) {
              _vm.selectedTags = $$v;
            },
            expression: "selectedTags"
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition",
      "max-width": "500"
    },
    model: {
      value: _vm.deleteItemDlg,
      callback: function callback($$v) {
        _vm.deleteItemDlg = $$v;
      },
      expression: "deleteItemDlg"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirmation")) + " ")]), _c('v-card-text', [_c('v-row', {
    staticClass: "text-h7 pa-3 pt-7"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_notifications_edit_confirmation_message")) + " ")])], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.deleteItemConfirm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]), _c('v-btn', {
    attrs: {
      "color": "red",
      "dark": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteItemDlg = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }