<template>
  <div class="">
    <Parent />
    <validation-observer ref="observer">
      <v-form ref="post_form" @submit.prevent="submit">
        <v-row class="">
          <v-col cols="12" md="9">
            <v-card>
              <v-card-title>
                <v-row align="center">
                  <v-col cols="auto">
                    {{ $t("page_notifications_edit_title") }}
                  </v-col>
                </v-row>
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" v-if="showDescription">
                      <div class="text-right mb-2">
                        <validation-provider
                          v-slot="{ errors }"
                          name="post_date"
                          rules=""
                        >
                          <v-dialog
                            ref="post_date_dialog"
                            v-model="post_date_model"
                            :return-value.sync="post_date"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                class="hint-text"
                              >
                                {{ $t("posted_date") }}:
                                {{ post_date }}
                                <v-icon size="16" right>$edit</v-icon>
                              </v-btn>
                              <v-text-field
                                dense
                                hidden
                                class="no-border"
                                v-model="post_date"
                                :error-messages="errors"
                                v-bind="attrs"
                                v-on="on"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="post_date" scrollable>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="post_date_model = false"
                              >
                                {{ $t("cancel") }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.post_date_dialog.save(post_date)"
                              >
                                {{ $t("ok") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </validation-provider>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        name="title"
                        :rules="rules.title"
                      >
                        <v-text-field
                          solo
                          hide-details
                          :placeholder="$t('post_title')"
                          dense
                          class="post-title"
                          v-model="title"
                          :error-messages="errors"
                          @input="enteredTextTitle = title.length"
                          maxlength="100"
                        >
                        </v-text-field>
                        <div class="error--text m-0" v-if="errors[0]">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                      <template>
                        <div class="hint-text text-md-right">
                          {{ $t("word_count", { count: enteredTextTitle }) }}
                        </div>
                      </template>
                    </v-col>
                    <v-col cols="12">
                      <div ref="previewTitle"></div>
                    </v-col>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                      >
                        <PostEditor
                          v-if="showDescription"
                          :data.sync="description"
                          :error-messages="errors"
                        ></PostEditor>
                        <div ref="preview"></div>
                        <div class="error--text m-0" v-if="errors[0]">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="3">
            <v-row>
              <v-col cols="12" order="last" order-md="first">
                <v-card class="text-center">
                  <v-card-text class="px-0 py-0">
                    <v-btn
                      tile
                      block
                      text
                      depressed
                      min-height="50"
                      class="custom-button"
                      @click="submit('draft')"
                    >
                      {{ $t("save_draft") }}
                    </v-btn>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-text class="px-0 py-0">
                    <v-btn
                      v-if="showDescription"
                      tile
                      block
                      text
                      depressed
                      min-height="50"
                      class="custom-button"
                      @click="showPreview"
                    >
                      {{ $t("preview") }}
                    </v-btn>
                    <v-btn
                      v-else
                      tile
                      block
                      text
                      depressed
                      min-height="50"
                      class="custom-button"
                      @click="disablePreview"
                    >
                      編集モード
                    </v-btn>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-btn
                          @click="deleteItemDlg = true"
                          class="custom-button delete"
                          text
                        >
                          {{ $t("delete_post") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-btn
                          type="submit"
                          class="custom-button"
                          min-width="100"
                          color="primary"
                          depressed
                        >
                          {{ $t("publish") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card>
                  <v-card-text class="px-0 py-0">
                    <v-container class="py-0">
                      <v-row align="center">
                        <v-col
                          cols="3"
                          md="12"
                          lg="3"
                          class="text-center label-type"
                        >
                          {{ $t("type") }}
                        </v-col>

                        <v-divider
                          :vertical="this.$vuetify.breakpoint.name !== 'md'"
                        ></v-divider>

                        <v-col cols="9" md="12" lg="9" class="px-2 py-0">
                          <validation-provider
                            v-slot="{ errors }"
                            name="selectedType"
                            :rules="rules.selectedType"
                          >
                            <v-select
                              solo
                              hide-details
                              flat
                              class="rounded-0 font-12px notify-type-select"
                              color="primary"
                              item-text="name"
                              item-value="id"
                              label="Select Type"
                              :items="notificationTypes"
                              v-model="selectedType"
                              :error-messages="errors"
                            >
                            </v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="category-card">
                  <v-card-title>
                    {{ $t("category") }}
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-card-text>
                    <validation-provider
                      v-slot="{ errors }"
                      name="selectedCategory"
                      :rules="rules.selectedCategory"
                    >
                      <v-radio-group
                        class="mt-0"
                        v-model="selectedCategory"
                        :error-messages="errors"
                      >
                        <v-radio
                          v-for="category in categories"
                          :key="category.id"
                          :label="category.name"
                          :value="category.id"
                          :ripple="false"
                        ></v-radio>
                      </v-radio-group>
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card class="category-card">
                  <v-card-title>
                    {{ $tc("tag", 2) }}
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-card-text>
                    <validation-provider
                      v-slot="{ errors }"
                      name="selectedTags"
                      :rules="rules.selectedTags"
                    >
                      <v-autocomplete
                        v-model="selectedTags"
                        :error-messages="errors"
                        :items="tags"
                        item-text="name"
                        item-value="name"
                        :menu-props="{ maxHeight: '400' }"
                        label="タグを選択してください。"
                        multiple
                        dense
                        chips
                        deletable-chips
                        small-chips
                        class="multiple-select-tag"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="deleteItemDlg"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          {{ $t("confirmation") }}
        </v-toolbar>
        <v-card-text>
          <v-row class="text-h7 pa-3 pt-7">
            {{ $t("page_notifications_edit_confirmation_message") }}
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" class="mr-3" @click="deleteItemConfirm">
            {{ $t("confirm") }}
          </v-btn>
          <v-btn color="red" dark @click="deleteItemDlg = false">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Parent from "@/views/admin/Notification/index";
import PostEditor from "@/components/admin/partials/PostEditor/PostEditor";
import _ from "lodash";
import dayjs from "@/plugins/dayjs";

export default {
  name: "index",
  components: { PostEditor, Parent },
  props: {
    mode: {
      type: String,
      default: "create",
      required: false
    }
  },
  created() {
    this.getCategoryFromApi();
  },

  computed: {
    categories() {
      return this.$store.getters.allCategories;
    },
    tags() {
      return this.$store.getters.allTags;
    },
    notificationTypes() {
      return this.$store.getters.allNotificationTypes;
    }
  },

  data() {
    return {
      singleNotification: {},
      post_date_model: false,
      formStatus: false,
      post_date: dayjs().format("YYYY-MM-DD"),
      is_draft: false,
      selectedTags: null,
      selectedCategory: "",
      selectedType: "",
      title: null,
      showDescription: true,
      rules: {
        title: "required",
        description: "required",
        selectedCategory: "required",
        selectedType: "required"
      },
      enteredTextTitle: 0,

      description: "",
      loading: false,
      deleteItemDlg: false,
      errors: ""
    };
  },
  methods: {
    disablePreview() {
      this.showDescription = true;
      this.$refs.preview.innerHTML = "";
      this.$refs.previewTitle.innerHTML = "";
    },
    showPreview() {
      this.showDescription = false;
      this.$refs.preview.innerHTML = this.description;
      this.$refs.previewTitle.innerHTML = this.title;
    },
    async deleteItemConfirm() {
      this.deleteItemDlg = false;
      await this.$store.dispatch(
        "DELETE_NOTIFICATION",
        this.singleNotification
      );
      this.$router.push({ name: "NotificationList" });
    },
    fillFields() {
      const data = this.$store.getters.singleNotification;
      this.singleNotification = data;
      if (data) {
        this.title = data.title;
        this.description = data.description;
        this.selectedCategory = data.category.id;
        this.selectedType = data.type.id;
        this.post_date = data.post_date;
        this.is_draft = data.is_draft;
        this.selectedTags = _.map(data.tags, function(o) {
          return _.pick(o, "name");
        });

        this.enteredTextTitle = this.title.length;
      }
    },
    async getCategoryFromApi() {
      this.loading = true;
      let a = this.$store.dispatch("GET_ALL_CATEGORIES");
      let b = this.$store.dispatch("GET_ALL_TAGS");
      let c = this.$store.dispatch("GET_ALL_NOTIFICATION_TYPES");
      let e = this.$store.dispatch("NOTIFICATION_GET", {
        id: this.$route.params.id
      });
      Promise.all([a, b, c, e])
        .then(() => {
          this.fillFields();
        })
        .catch(() => {
          this.$store.dispatch("ALERT", {
            show: true,
            text: this.$t("error_msg")
          });
        });
      this.loading = false;
    },
    async submit(statusType) {
      this.formStatus = true;
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        let data = {};

        data.id = this.$route.params.id;
        data.title = this.title;
        data.description = this.description;
        data.category_id = this.selectedCategory;
        data.type_id = this.selectedType;
        data.post_date = this.post_date
          ? this.post_date
          : dayjs().format("YYYY-MM-DD");
        data.tags = this.selectedTags;
        data.is_draft = 0;
        if (statusType == "draft") {
          data.is_draft = 1;
        }
        this.$store
          .dispatch("EDIT_NOTIFICATION", data)
          .then(result => {
            if (result.status === 200) {
              this.$router.push({ name: "NotificationList" });
              this.$store.dispatch("STATS_REQUEST");
            }
          })
          .finally(() => {
            this.formStatus = false;
          })
          .catch(error => {
            this.$refs.observer.setErrors(error.data.error.errors);
          });
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
